const replaceThreeSybmols = (str: any) => String(str).replace(/^.{3}/g, '***');

const replaceLastThreeSymbols = (str: any) => `${String(str).slice(0, -3)}***`;

export const mailCensor = (email: any) => {
  if(email !== undefined){
    const arr = email.split('@');
    return `${replaceLastThreeSymbols(arr[0])}@${replaceThreeSybmols(arr[1])}`;
  }
  return '';
};
