import { pathToRegexp } from 'path-to-regexp';

export const isPagePath = (pathname: any, mask: any) => {
  if (Array.isArray(mask)) {
    let found = false;
    mask.map(item => {
      const regexp = pathToRegexp(item);
      if (regexp.test(pathname)) found = true;
    })
    return found;
  }
  const regexp = pathToRegexp(mask);
  return regexp.test(pathname);
}

export const isPagePathStartsWith = (pathname: any, mask: any) => {
  if (!pathname) pathname = '';
  const pathnameArray = pathname.split('/').filter((x: any) => x);
  const maskArray = mask.split('/').filter((x: any) => x);
  pathname = `/${pathnameArray.slice(0, maskArray.length).join('/')}`;
  return isPagePath(pathname, mask);
}

export const isDashboard = (pathname: any) => {
  return isPagePathStartsWith(pathname, '/:lang/dashboard') || isPagePathStartsWith(pathname, '/dashboard');
}

export const isLanding = (pathname: any) => {
  return !isDashboard(pathname);
}
