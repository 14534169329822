import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      //@ts-ignore
      ph: 320,
      sm: 480,
      mb: 640,
      tb: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
      xxl: 2550
    }
  },
  palette: {
    primary: { main: '#03349d' },
    secondary: { main: '#FFE27A' },
    //@ts-ignore
    black: { main: '#272727' },
  }
});
