import axios from 'axios';
import Cookies from 'universal-cookie';
import Router from 'next/router';
import userServises from '../services/user.service';

const cookies = new Cookies();
const axiosInstance = axios.create({});
axiosInstance.interceptors.request.use((config) => {
  if (cookies.get('token') && cookies.get('token') !== null) {
    config.headers.Authorization = `Bearer ${cookies.get('token')}`;

    if (!config.headers['X-localization']) {
      config.headers['X-localization'] = cookies.get('next-i18next') === 'ru' ? 'ru' : 'en';
    }
  } else if (cookies.get('tokenBackup') && cookies.get('tokenBackup') !== null) {
    config.headers.Authorization = `Bearer ${cookies.get('tokenBackup')}`;
    if (!config.headers['X-localization']) {
      config.headers['X-localization'] = cookies.get('next-i18next') === 'ru' ? 'ru' : 'en';
    }
  } else {
    delete config.headers.Authorization;
  }
  return config;
}, (error) => Promise.reject(error));
axiosInstance.interceptors.response.use((response) => response, (error) => {
  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
    userServises.logout().then(() => typeof window !== 'undefined' && Router.push('/login', `/${cookies.get('next-i18next')}/login`));
  }
  return error;
});
(axiosInstance as any).CancelToken = axios.CancelToken;
export default axiosInstance;
