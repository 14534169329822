export const getMonthsArray = {
  en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  ru: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
}

export const getNewDateFromString = (date: any) => {
  if (typeof date !== 'string') return date;

  let splitter = '/';
  if (date.includes('-')) splitter = '-';
  if (date.includes('.')) splitter = '.';

  const dateArr = date.split(splitter);
  const formattedDate = `${dateArr[1]}${splitter}${dateArr[0]}${splitter}${dateArr[2]}`;

  return new Date(formattedDate);
}

export const isDate = (date: any) => {
  if (typeof date !== 'string') return false;
  const arrDate = date.split('/');

  if (arrDate.length !== 3) return false;
  if (arrDate[2].length !== 4) return false;
  if (+arrDate[1] > 12) return false;

  const formattedDate = date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
  return (new Date(formattedDate).toString() !== 'Invalid Date');
};
