import EventEmitter from 'events';

let ee: EventEmitter;
interface Process {
    browser: boolean;
}
interface Window {
    ee?: any
}
if ((process as Process).browser) {
  if (!(window as Window).ee) {
    ee = new EventEmitter();
  } else {
    ee = (window as Window).ee;
  }
}
export { ee };
