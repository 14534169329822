import { userConstants } from '../constants';
import { userService } from '../../services';

function success(user: any) { return { type: userConstants.LOGIN_SUCCESS, ...user }; }
function failure(error: any) { return { type: userConstants.LOGIN_FAILURE, error }; }

function verification(value: any) { return { type: userConstants.VERIFICATION_SUCCESS, value }; }
function userTypeAnd2FA(userType: any, fa2: any) { return { type: userConstants.USER_TYPE_AND_2FA, userType, fa2 }; }
function setInvoice(invoice: any) { return { type: userConstants.SET_INVOICE, invoice }; }
function setEmail(email: any) { return { type: userConstants.SET_USER_EMAIL, email }; }

const setUserLoggedIn = (isUserLoggedIn: any) => (dispatch: any) => {
  dispatch({ type: userConstants.SET_USER_LOGGED_IN, isUserLoggedIn });
};

const setAccrualsLength = (accrualsLength: any) => (dispatch: any) => {
  dispatch({ type: userConstants.SET_USER_ACCRUALS_LENGTH, accrualsLength });
};
const setNewAccrualsLength = (accrualsLength: any) => (dispatch: any) => {
  dispatch({ type: userConstants.SET_USER_ACCRUALS_LENGTH_NEW, accrualsLength });
};
const setReferallsLength = (referalsLength: any) => (dispatch: any) => {
  dispatch({ type: userConstants.SET_USER_REFERAL_LENGTH, referalsLength });
};
const setNewReferallsLength = (referalsLength: any) => (dispatch: any) => {
  dispatch({ type: userConstants.SET_USER_REFERAL_LENGTH_NEW, referalsLength });
};
const setFirstOperationID = (firstOperationID: any) => (dispatch: any) => {
  dispatch({ type: userConstants.SET_USER_FIRST_OPERATION_ID, firstOperationID });
};
const setAmountOfNewOperations = (newOperation: any) => (dispatch: any) => {
  dispatch({ type: userConstants.SET_USER_NEW_OPERATION, newOperation });
};

const getUserBalance = () => (dispatch: any) => userService.getUserBalance()
  .then(
    (res) => {
      dispatch({ type: userConstants.BALANCE_UPDATE, balance: res });
      return res;
    },
    (error) => {
      throw error;
    },
  );

const login = ({
  email,
  password,
  doubleFA = false,
}: any) => (dispatch: any) => userService.login({ email, password, doubleFA })
  .then(
    (res: any) => {
      dispatch(success({ token: res.data.token, email, auth_confirm_code: doubleFA }));
      dispatch({ type: userConstants.SET_USER_LOGGED_IN, isUserLoggedIn: true });
      return res;
    },
    (error) => {
      dispatch(failure(error));
      throw error;
    },
  );

const fa2SocialLogin = ({
  email,
  accessToken,
  doubleFA = false,
}: any) => (dispatch: any) => userService.fa2SocialLogin({ email, accessToken, doubleFA })
  .then(
    (res: any) => {
      dispatch(success({ token: res.data.token, email, auth_confirm_code: doubleFA }));
      dispatch({ type: userConstants.SET_USER_LOGGED_IN, isUserLoggedIn: true });
      return res;
    },
    (error) => {
      dispatch(failure(error));
      throw error;
    },
  );

const logout = () => (dispatch: any) => userService.logout()
  .then(
    (res) => {
      dispatch({ type: userConstants.LOGOUT });
      dispatch({ type: userConstants.SET_USER_LOGGED_IN, isUserLoggedIn: false });
      return res;
    },
    (error) => {
      dispatch(failure(error));
      throw error;
    },
  );

const getUserAvatar = () => (dispatch: any) => userService.getUserAvatar()
  .then(
    (avatar) => {
      dispatch({ type: userConstants.AVATAR_UPDATE, avatar });
    },
  );

const setUserAvatar = () => (dispatch: any) => {
  const avatar = userService.getUserAvatarUrlFromCookie();
  dispatch({ type: userConstants.AVATAR_UPDATE, avatar });
};

const setUserId = (id: any) => (dispatch: any) => {
  dispatch({ type: userConstants.SET_USER_ID, id });
}

const userActions = {
  login,
  logout,
  verification,
  userTypeAnd2FA,
  getUserBalance,
  setInvoice,
  setUserLoggedIn,
  setAccrualsLength,
  setNewAccrualsLength,
  setReferallsLength,
  setNewReferallsLength,
  setFirstOperationID,
  setAmountOfNewOperations,
  getUserAvatar,
  setUserAvatar,
  setEmail,
  fa2SocialLogin,
  setUserId,
};

export default userActions;
