export const declOfNum = (number: any, titles: any) => {
  return titles[
    number % 10 === 1 && number % 100 !== 11
      ? 0
      : number % 10 >= 2 &&
        number % 10 <= 4 &&
        (number % 100 < 10 || number % 100 >= 20)
      ? 1
      : 2
  ];
};

export const onlyFirstDelimiter = (string: any, delimiter: any) => {
  const [first, ...rest] = string.split(delimiter);
  return rest.length ? first + delimiter + rest.join("") : first;
};

export const formatInputNumber = (
  number: string | number,
  symbolsAfterDot?: any
) => {
  const dotsOnly = String(number).replace(/[,-/]/, ".");
  const numbersAndDotsOnly = dotsOnly.replace(/[^0-9.]/, "");
  const valueWithOneDelimiter = onlyFirstDelimiter(numbersAndDotsOnly, ".");

  if (symbolsAfterDot) {
    const firstpart = valueWithOneDelimiter.split(".")[0];
    const secpart = valueWithOneDelimiter.split(".")[1];
    let secpartres;

    if (secpart) {
      secpartres = secpart.substr(0, symbolsAfterDot);
      const res = `${firstpart}.${secpartres}`;
      return res;
    }
  }

  return valueWithOneDelimiter;
};

//запрет чисел
export const withoutNumbersFn = (e: any) => {
  const value = e.target.value;
  const regex = /^[0-9,.]+$/g;
  if (regex.test(value)) {
    return ''
  }
  return value;
};
