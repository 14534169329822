import { ee } from '../helpers';
export const createPopup = (params: any) => {
    if ((process as any).browser) {
        if ((window as any).popupMounted) {
                    ee.emit('createPopup', params);
        }
        else {
                    ee.on('popupMounted', () => {
                            ee.emit('createPopup', params);
            });
        }
    }
};
export const openPopup = (id: any) => {
    if ((process as any).browser) {
        if ((window as any).popupMounted) {
                    ee.emit('openPopup', id);
        }
        else {
                    ee.on('popupMounted', () => {
                            ee.emit('openPopup', id);
            });
        }
    }
};
export const closePopup = (id: any) => {
    if ((process as any).browser) {
        if ((window as any).popupMounted) {
                    ee.emit('closePopup', id);
        }
        else {
                    ee.on('popupMounted', () => {
                            ee.emit('closePopup', id);
            });
        }
    }
};
export const updatePopup = (id: any) => {
    if ((process as any).browser) {
        if ((window as any).popupMounted) {
                    ee.emit('updatePopup', id);
        }
        else {
                    ee.on('popupMounted', () => {
                            ee.emit('updatePopup', id);
            });
        }
    }
};
