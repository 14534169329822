export default function popUpScrollForbiden(windowWidth: any, arr: any) {
  if (windowWidth <= 767) {
    let status = false;
    arr.map((popUpStatus: any) => {
      if (popUpStatus !== null && (popUpStatus === true || typeof popUpStatus === 'object')) {
        status = true;
      }
    });
  
    if (Boolean(status) === true) {
      document.body.style.maxHeight = '100vh';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.maxHeight = '';
      document.body.style.overflow = 'visible';
    }
  } else {
    arr.map((popUpStatus: any) => {
      if (popUpStatus && (popUpStatus === true || typeof popUpStatus === 'object')) {
        document.body.classList.add('no-space__for-main');
      }
    });
  }
}
