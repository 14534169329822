// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'style' implicitly has an 'any' type.
const textStyleReturner = (style, weight, size, height, color) => ({
  fontFamily: 'Montserrat',
  fontStyle: style,
  fontWeight: weight,
  fontSize: size,
  lineHeight: height,
  color
});

export default textStyleReturner;
