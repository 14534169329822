import Cookies from 'universal-cookie';
import Router from 'next/router';
import {
  axios, ee, toast, languageReturner, getApiServerUrl
} from '../helpers';

const cookies = new Cookies();
async function getUserBalance() {
  return axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/dashboard/balance`)
    .then((res) => {
      if (res && res.data !== undefined && res.data.data) {
        const { data } = res.data;
        const {
          btc_balance, ltc_balance, ust_balance, eth_balance,
        } = data;
        const balance = {
          btc: {
            amount: btc_balance.amount,
            usdAmount: btc_balance.usd_amount,
          },
          ltc: {
            amount: ltc_balance.amount,
            usdAmount: ltc_balance.usd_amount,
          },
          ust: {
            amount: ust_balance.amount,
            usdAmount: ust_balance.usd_amount,
          },
          eth: {
            amount: eth_balance.amount,
            usdAmount: eth_balance.usd_amount,
          },
        };
        cookies.set('balance', balance);
        return balance;
      }
      return false;
    });
}
function redirectAfterLogin({ language, res = {}, _allUserFieldsAreFilled = null }: any) {
  const verifyEmailRedirect = cookies.get('verifyEmailRedirect');
  if (verifyEmailRedirect !== undefined) {
    window.location.href = verifyEmailRedirect;
    cookies.remove('verifyEmailRedirect', { path: '/' });
    return true;
  }
  if (res.data) {
    if (res.data.is_approved === false) {
      cookies.set('is_approved', 'false', { path: '/' });
      Router.push('/email-verification', `${languageReturner(language)}/email-verification`);
      return true;
    }
    if (res.data.is_approved === true) {
      cookies.set('is_approved', 'true', { path: '/' });
    }
  }
  const redirectLink = cookies.get('redirect');
  let allUserFieldsAreFilled = cookies.get('allUserFieldsAreFilled') === 'true';
  if (!_allUserFieldsAreFilled) {
    allUserFieldsAreFilled = _allUserFieldsAreFilled;
  }
  if (redirectLink && allUserFieldsAreFilled) {
    Router.push(redirectLink, `${languageReturner(language)}${redirectLink}`);
    cookies.remove('redirect', { path: '/' });
    return true;
  }
  Router.push('/dashboard', `${languageReturner(language)}/dashboard`);
  return true;
}
async function login({
  email, password, doubleFA = false, remember_me = true,
}: any) {
  const obj = {
    email,
    password,
    remember_me,
  };
  if (doubleFA) {
    (obj as any).auth_confirm_code = doubleFA;
  }
  return axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/login`, obj, {
    headers: {
      'X-localization': cookies.get('next-i18next') === 'ru' ? 'ru' : 'en',
    },
  }).then((res) => {
    const options:{[index: string]: any} = {
      path: '/',
      sameSite: true,
    };
    if ((process as any).browser) (options as any).domain = window.location.hostname;
    if (res !== null && res !== undefined && res.status === 200 && res.data !== undefined) {
      cookies.set('token', res.data.token, {
        path: '/',
      });
      cookies.set('email', email, options);
      return res;
    }
    if ((res as any).response.status === 422) {
      const faFirst = cookies.get('2faFirst', options);
      if (faFirst === undefined) {
        toast('error', 'Введите 2fa код');
      } else if (res.status !== 200 && (res as any).response !== undefined) {
        toast('error', (res as any).response.data.message);
      }
      cookies.set('2faFirst', false, options);
      ee.emit('show2Fa', true);
    } else if (res.status !== 200 && (res as any).response !== undefined && res.status !== 422) {
      toast('error', (res as any).response.data.message);
    } else {
      return res;
    }
  });
}
async function fa2SocialLogin({
  email, accessToken, doubleFA = false,
}: any) {
  const obj = {
    token: accessToken,
  };
  if (doubleFA) {
    (obj as any).auth_confirm_code = doubleFA;
  }
  const provider = cookies.get('provider');

  return axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/login/social/${provider}`, obj, {
    headers: {
      'X-localization': cookies.get('next-i18next') === 'ru' ? 'ru' : 'en',
    },
  }).then((res) => {
    const options:{[index: string]: any} = {
      path: '/',
      sameSite: true,
    };
    if ((process as any).browser) (options as any).domain = window.location.hostname;
    if (res !== null && res !== undefined && res.status === 200 && res.data !== undefined) {
      cookies.set('token', res.data.token, {
        path: '/',
      });
      cookies.set('email', email, options);
      return res;
    }
    if (res.status !== 200 && (res as any).response !== undefined && res.status !== 422) {
      toast('error', (res as any).response.data.message);
    } else {
      return res;
    }
  });
}

async function register({
  firstname, lastname, email, password, confirm_password, user_agree, affiliate_code, biggi_cookie, registration_promocode, aads_cookie, _3snet_cookie, utm_tags,
}: any) {
  const obj = {
    firstname,
    lastname,
    email,
    password,
    confirm_password,
    user_agree,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  if (affiliate_code) {
    (obj as any).affiliate_code = affiliate_code;
  }
  if (registration_promocode) {
    (obj as any).registration_promocode = registration_promocode;
  }
  if (biggi_cookie) {
    (obj as any).affiliate = biggi_cookie;
  }
  if (aads_cookie) {
    (obj as any).affiliate = aads_cookie;
  }
  if (_3snet_cookie) {
    (obj as any).affiliate = _3snet_cookie;
  }
  if (utm_tags) {
    (obj as any).utm_tags = utm_tags;
  }
  return axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/register`, obj, {
    headers: {
      'X-localization': cookies.get('next-i18next') === 'ru' ? 'ru' : 'en',
    },
  });
}

async function sendEmail(email: string) {
  return axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/register`,
  { email }, {
    headers: {
      'X-localization': cookies.get('next-i18next') === 'ru' ? 'ru' : 'en',
    },
  })
}

function saveRedirectUrl(location: any) {
  cookies.set('redirect', location, { path: '/' });
}
function removeRedirectUrl() {
  cookies.remove('redirect', { path: '/' });
}
function isUserLoggedIn() {
  return cookies.get('token') != null;
}
function checkIsUserLoggedInAndHasFullInfo() {
  if (!isUserLoggedIn()) {
    cookies.set('allUserFieldsAreFilled', false, { path: '/' });
  }
}
function isUserHasAvatar(cookie = {}) {
  const _cookies = new Cookies(cookie);
  const cookieAvatar = _cookies.get('avatar');
  const cookieAvatarForEmail = _cookies.get('avatarForEmail');
  const cookieEmail = _cookies.get('email');
  return !!cookieAvatar && cookieEmail === cookieAvatarForEmail;
}
function getUserAvatarUrlFromCookie(cookie = {}) {
  const _cookies = new Cookies(cookie);
  return _cookies.get('avatar');
}
async function getUserAvatar() {
  const _cookies = new Cookies();
  return axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/profile`).then((res) => {
    if (res !== undefined && res !== null && res.data !== undefined && res.data.data !== undefined) {
      let { email, avatar } = res.data.data;
      if (avatar) {
        avatar = `${avatar}`;
      } else {
        avatar = '/static/img/noAvatar.png';
      }
      const options = { path: '/' };
      _cookies.set('avatar', avatar, options);
      _cookies.set('avatarForEmail', email, options);
      return avatar;
    }
  });
}
function logout() {
  return new Promise((resolve) => {
    const options = {
      path: '/',
    };
    cookies.set('allUserFieldsAreFilled', false, options);
    const tokenOptions = {
      path: '/',
      sameSite: true,
    };
    if ((process as any).browser) (tokenOptions as any).domain = window.location.hostname;
    cookies.remove('token', { path: '/' });
    cookies.remove('email', options);
    cookies.remove('avatarForEmail', options);
    cookies.remove('2faFirst', options);
    cookies.remove('verification', options);
    cookies.remove('fullProfile', options);
    cookies.remove('userID', options);
    resolve(true);
  });
}


const userService = {
  login,
  logout,
  isUserLoggedIn,
  isUserHasAvatar,
  getUserAvatarUrlFromCookie,
  getUserBalance,
  removeRedirectUrl,
  saveRedirectUrl,
  getUserAvatar,
  register,
  redirectAfterLogin,
  checkIsUserLoggedInAndHasFullInfo,
  fa2SocialLogin,
  sendEmail
};
export default userService;
