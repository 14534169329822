function parseJwt(token: any) {
  if (token !== null || token !== undefined) {
    const base64String = token.split('.')[1];
    return JSON.parse(Buffer.from(base64String, 'base64').toString('ascii'));
  }
  return null;
}

export const getUserIDbyToken = (token: any) => {
  const tokenData = parseJwt(token);
  return tokenData.sub;
};
