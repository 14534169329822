const convert = (n: any) => {
  const sign = +n < 0 ? '-' : '';
  const toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  const [lead, decimal, pow] = n.toString()
    .replace(/^-/, '')
    .replace(/^([0-9]+)(e.*)/, '$1.$2')
    .split(/e|\./);
  return +pow < 0
    ? `${sign}0.${'0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0))}${lead}${decimal}`
    : sign + lead + (+pow >= decimal.length ? (decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))) : (`${decimal.slice(0, +pow)}.${decimal.slice(+pow)}`));
};
const convertToUSD = (result: any) => {
  result = Number(result);

  if (result < 0) return `-$${Math.abs(result)}`;
  return `$${result}`;
};

export const coinDivider = (number?: any, type?: any, fix?: any) => {
  let fixed;
  number = Number(number);
  type = type ? String(type).toLowerCase() : '$';

  if (type === 'ust') {
    fixed = 2;
  } else if (type === 'savings') {
    if (Number(number.toFixed(6))) {
      fixed = 6;
    } else {
      fixed = 10;
    }
  } else {
    fixed = 6;
  }
  if (fix) {
    fixed = fix;
  }
  let result = convert(Number(number.toFixed(fixed)));

  const str = result.toString();
  if (str.indexOf('e') !== -1) {
    const exponent = parseInt(str.split('-')[1], 10);
    result = convert(result.toFixed(exponent));
    if (type === '$') return convertToUSD(result);
    return result;
  }
  if (type === '$') return convertToUSD(result);
  return result;
};
