const ERRORS = [
  {
    id: 1,
    type: 'error',
    text: 'Недостаточно средств на баллансе'
  },
  {
    id: 2,
    type: 'error',
    text: 'Запрашиваемая вами страница не найдена!'
  },
  {
    id: 3,
    type: 'error',
    text: 'Пароли не совпадают'
  },
  {
    id: 4,
    type: 'error',
    text: 'Пароль не должен содержать в себе пробелы'
  },
  {
    id: 5,
    type: 'success',
    text: 'Операция выполнена успешно'
  }
];
export default ERRORS;
