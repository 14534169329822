const prod = process.env.NODE_ENV;
const port = process.env.PORT;
const backendUrl = process.env.BACKEND_URL;
const backendUrlLocal = process.env.BACKEND_URL_LOCAL || backendUrl;
const prodLocally = process.env.NODE_ENV_CHECK_PROD_LOCALLY === 'true';
const getApiServerUrl = () => {
    let apiServerUrl;
    if (prod === 'production' && port && +port === 3000 && !prodLocally) {
        // Test
        if ((process).browser) {
            apiServerUrl = backendUrl;
        }
        else {
            apiServerUrl = backendUrlLocal;
        }
    }
    else if (port && +port === 3001 && !prodLocally) {
        // Prod
        if ((process).browser) {
            apiServerUrl = backendUrl;
        }
        else {
            apiServerUrl = backendUrlLocal;
        }
    }
    else {
        // Local
        apiServerUrl = backendUrl; // http://crypcloudvel.loc
    }
    return apiServerUrl;
};
const getDomainUrl = () => {
    return (process).browser ? `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}` : null;
};
module.exports = { getApiServerUrl, getDomainUrl };
