import Cookies from 'universal-cookie';
import { userActions } from '@redux/actions';

const getFirstOperationIDFromOperations = (operations: any) => {
  let firstOperationID = null;
  for (const operationDateKey of Object.keys(operations)) {
    for (const [operationKey, operation] of Object.entries(operations[operationDateKey])) {
      if (operation && (operation as any).id) firstOperationID = (operation as any).id;
      if (firstOperationID) break;
    }
    if (firstOperationID) break;
  }
  return firstOperationID;
};
const getAmountOfNewOperations = (operations: any) => {
  const cookies = new Cookies();
  const cookieFirstOperationID = cookies.get('first_operation_id');
  let skip = false;
  let total = 0;
  Object.entries(operations).map(([operationsDateKey, operationsItems]: any) => {
    Object.entries(operationsItems).map(([operationID, operation] : any) => {
      if (Number(cookieFirstOperationID) === Number((operation as any).id)) skip = true;
      if (!skip) total += 1;
    });
  });
  return total;
};
export const getFilteredOperations = (operations: any, cb: any) => {
  const filteredOperations: {[index:string]:any} = {};
  let filteredCount = 0;
  Object.entries(operations).map(([operationsDate, operationsItems]: any) => {
    Object.entries(operationsItems).map(([operationID, operation]: any) => {
      if (cb(operation)) {
        filteredOperations[operationsDate] = {
          ...filteredOperations[operationsDate],
          [operationID]: operation,
        };
        filteredCount += 1;
      }
    });
  });
  return [filteredOperations, filteredCount];
};
export const saveFirstOperationIdInCookie = (operations: any) => {
  const cookies = new Cookies();
  const firstOperationID = getFirstOperationIDFromOperations(operations);
  if (firstOperationID) {
    cookies.set('first_operation_id', firstOperationID, { path: '/' });
  }
};
export const setAmountOfNewOperations = (dispatch: any, operations: any, resetAmount = false) => {
  if (!(typeof operations === 'object' && operations !== null)) return false;
  const firstOperationID = getFirstOperationIDFromOperations(operations);
  if (!firstOperationID) return false;
  const cookies = new Cookies();
  const cookieFirstOperationID = cookies.get('first_operation_id');
  const cookieAmountOfNewOperation = cookies.get('amount_of_new_operations');
  if (+cookieFirstOperationID !== +firstOperationID) {
    const amount = getAmountOfNewOperations(operations);
    cookies.set('first_operation_id', firstOperationID, { path: '/' });
    cookies.set('amount_of_new_operations', amount, { path: '/' });
    dispatch(userActions.setAmountOfNewOperations(amount));
    dispatch(userActions.setFirstOperationID(firstOperationID));
  } else if (+cookieAmountOfNewOperation) {
    dispatch(userActions.setAmountOfNewOperations(cookieAmountOfNewOperation));
  }
  if (resetAmount) {
    setTimeout(() => {
      cookies.set('amount_of_new_operations', 0, { path: '/' });
      dispatch(userActions.setAmountOfNewOperations(0));
    }, 5000);
  }
};
