const userConstants = {
  AVATAR_UPDATE: 'AVATAR_UPDATE',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  VERIFICATION_SUCCESS: 'VERIFICATION_SUCCESS',
  USER_TYPE_AND_2FA: 'USER_TYPE_AND_2FA',
  BALANCE_UPDATE: 'BALANCE_UPDATE',
  LOGOUT: 'LOGOUT',
  SET_INVOICE: 'SET_INVOICE',
  SET_USER_LOGGED_IN: 'SET_USER_LOGGED_IN',
  SET_USER_ACCRUALS_LENGTH: 'SET_USER_ACCRUALS_LENGTH',
  SET_USER_ACCRUALS_LENGTH_NEW: 'SET_USER_ACCRUALS_LENGTH_NEW',
  SET_USER_REFERAL_LENGTH: 'SET_USER_REFERAL_LENGTH',
  SET_USER_REFERAL_LENGTH_NEW: 'SET_USER_REFERAL_LENGTH_NER',
  SET_USER_FIRST_OPERATION_ID: 'SET_USER_FIRST_OPERATION_ID',
  SET_USER_NEW_OPERATION: 'SET_USER_NEW_OPERATION',
  SET_USER_EMAIL: 'SET_USER_EMAIL',
  SET_USER_FIRST_OPERATION: 'SET_USER_FIRST_OPERATION',
  SET_USER_ID: 'SET_USER_ID'

};
export default userConstants;
